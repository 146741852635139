import React from 'react';
import SEO from "../common/SEO";
import HeaderTopBar from '../common/header/HeaderTopBar';
import FooterThree from '../common/footer/FooterThree';

import HeaderBackOff from "../common/header/Header";
import Separator from "../elements/separator/Separator";

const Terms = () => {
    return (
        <>
            <SEO title="Mentions Légales - Backoff Solutions" />
            <main className="page-wrapper">

                <HeaderTopBar/>
                <HeaderBackOff btnStyle="btn-small" HeaderSTyle="header-not-transparent"/>


                {/* Start Slider Area  */}
                <Separator />

                <div style={{
                    margin: "auto",
                    maxWidth: '960px',
                    padding: '35px 0',
                }}>
                    <h1>Mentions légales</h1>
                    <section>
                        <h3>1. Éditeur du site</h3>
                        <p>
                            Le présent site est édité par : <br/>
                            <strong>BackOff Solutions</strong> <br/>
                            SARL au capital de 3000 TND immatriculé à Tunis sous le numéro 1896736W.<br/>
                            Siège social : 11, Rue Kheireddine Barberousse La Marsa 2070 Tunis <br/>
                            Directeur de publication : Halima Amari <br/>
                            Adresse e-mail : <a href="mailto:admin@backoff.solutions">admin@backoff.solutions</a> <br/>
                            Téléphone : +33 04 28 67 71 44
                        </p>
                    </section>
                    <section>
                        <h3>2. Hébergement du site</h3>
                        <p>
                            Le site est hébergé par : <br/>
                            <strong>Vercel Inc</strong> <br/>
                            Site web : <a href="https://vercel.com/" target="_blank">https://vercel.com</a>
                        </p>
                    </section>
                    <section>
                        <h3>3. Propriété intellectuelle</h3>
                        <p>
                            Le contenu du site (textes, images, graphismes, logos, icônes, etc.) est la propriété
                            exclusive de
                            <strong>BackOff Solutions</strong>, sauf mention contraire. <br/>
                            Toute reproduction, représentation, modification, publication, ou adaptation des éléments du
                            site, quel que soit
                            le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable
                            de <strong>BackOff Solutions</strong>.
                        </p>
                    </section>
                    <section>
                        <h3>4. Données personnelles</h3>
                        <p>
                            Conformément à la réglementation en vigueur, notamment le Règlement Général sur la
                            Protection des Données (RGPD), vos
                            données personnelles recueillies sur ce site font l’objet d’un traitement uniquement à des
                            fins de gestion des demandes. <br/>
                            Pour toute question ou exercice de vos droits (accès, rectification, suppression, etc.),
                            contactez-nous à :
                            <a href="mailto:dpo@backoff.solutions">dpo@backoff.solutions</a>.
                        </p>
                    </section>
                    <section>
                        <h3>5. Cookies</h3>
                        <p>
                            Le site utilise des cookies pour améliorer l’expérience utilisateur. Vous pouvez à tout
                            moment configurer vos préférences
                            via notre bandeau cookie ou en modifiant les paramètres de votre navigateur.
                        </p>
                    </section>
                    <section>
                        <h3>6. Limitation de responsabilité</h3>
                        <p>
                            <strong>BackOff Solutions</strong> met tout en œuvre pour fournir des informations exactes
                            et à jour sur son site. Cependant,
                            la société ne peut être tenue responsable des erreurs ou omissions dans le contenu, ni des
                            dommages directs ou indirects
                            résultant de l’utilisation du site.
                        </p>
                    </section>
                    <section>
                        <h3>7. Droit applicable et juridiction compétente</h3>
                        <p>
                            Les présentes mentions légales sont régies par le droit tunisien. En cas de litige, et à
                            défaut de résolution amiable, les
                            tribunaux compétents seront ceux dépendant du siège social de <strong>BackOff
                            Solutions</strong>.
                        </p>
                    </section>
                    <section>
                        <h3>8. Contact</h3>
                        <p>
                            Pour toute question concernant les mentions légales, veuillez nous contacter à : <br/>
                            <strong>BackOff Solutions</strong> <br/>
                            E-mail : <a href="mailto:admin@backoff.solutions">admin@backoff.solutions</a> <br/>
                            Téléphone : +33 04 28 67 71 44
                        </p>
                    </section>
                </div>
                <FooterThree/>
            </main>
        </>
    )
}

export default Terms;
